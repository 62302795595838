"use strict";

var _interopRequireDefault = require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("E:\\oms\\dxmh-web-boot\\node_modules\\_core-js@2.6.12@core-js\\modules\\es6.array.iterator.js");
require("E:\\oms\\dxmh-web-boot\\node_modules\\_core-js@2.6.12@core-js\\modules\\es6.promise.js");
require("E:\\oms\\dxmh-web-boot\\node_modules\\_core-js@2.6.12@core-js\\modules\\es6.object.assign.js");
require("E:\\oms\\dxmh-web-boot\\node_modules\\_core-js@2.6.12@core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _lang = _interopRequireDefault(require("./lang"));
require("./icons");
require("./utils/error-log");
var _request = _interopRequireDefault(require("@/utils/request"));
var filters = _interopRequireWildcard(require("./filters"));
var _permissionDirect = require("./utils/permissionDirect");
var _vueSimpleUploader = _interopRequireDefault(require("vue-simple-uploader"));
require("e-icon-picker/dist/symbol.js");
require("e-icon-picker/dist/index.css");
require("font-awesome/css/font-awesome.min.css");
require("element-ui/lib/theme-chalk/icon.css");
var _iconfont = _interopRequireDefault(require("./styles/iconfont.json"));
require("./styles/iconfont.css");
var _eIconPicker = _interopRequireWildcard(require("e-icon-picker"));
var _getSvg = _interopRequireDefault(require("e-icon-picker/dist/getSvg"));
var _Print = _interopRequireDefault(require("./utils/Print"));
var _vuePrintNb = _interopRequireDefault(require("vue-print-nb"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
/*
 * @Author: wangtao
 * @Date: 2021-04-23 14:55:33
 * @LastEditors: 谭鑫
 * @LastEditTime: 2022-06-27 09:38:18
 * @Description: file content
 */

// a modern alternative to CSS resets

// global css

// internationalization
// icon
// error log

// global filters

//基本彩色图标库
// 基本样式，包含基本图标
//font-awesome 图标库
//element-ui 图标库
//自己的图标信息
//自己的图标样式

_vue.default.use(_vuePrintNb.default);
_vue.default.use(_Print.default);
var forIconfont = (0, _eIconPicker.analyzingIconForIconfont)(_iconfont.default);
//全局删除增加图标
_vue.default.use(_eIconPicker.default, {
  FontAwesome: true,
  ElementUI: true,
  eIcon: true,
  //自带的图标，来自阿里妈妈
  eIconSymbol: true,
  //是否开启彩色图标
  addIconList: forIconfont.list,
  removeIconList: []
});
_eIconPicker.iconList.addIcon(_getSvg.default);
// iconList.removeIcon(["el-icon-s-ticket"]);

var Plugins = [_permissionDirect.hasPermission, _permissionDirect.hasNoPermission, _permissionDirect.hasAnyPermission, _permissionDirect.hasAnyRole, _permissionDirect.hasNoRole, _permissionDirect.hasRole];
Plugins.map(function (plugin) {
  _vue.default.use(plugin);
});
_vue.default.use(_elementUi.default, {
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
_vue.default.use(_vueSimpleUploader.default);
_vue.default.prototype.$post = _request.default.post;
_vue.default.prototype.$get = _request.default.get;
_vue.default.prototype.$put = _request.default.put;
_vue.default.prototype.$delete = _request.default.delete;
_vue.default.prototype.$download = _request.default.download;
_vue.default.prototype.$upload = _request.default.upload;
_vue.default.prototype.$login = _request.default.login;

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});