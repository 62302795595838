exports = module.exports = require("../../node_modules/_css-loader@1.0.1@css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "rgba(255,255,255,0.7)",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#393e46",
	"menuHover": "#313131",
	"subMenuBg": "#393e46",
	"subMenuHover": "#313131",
	"sideBarWidth": "235px",
	"menuTextHover": "#fff"
};