var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-avatar--wrapper",
      style: [_vm.style, _vm.customStyle],
      attrs: { "aria-hidden": "true" },
    },
    [
      _vm.isUpload
        ? _c("div", { staticClass: "avaterUpload" }, [_vm._v("点击上传头像")])
        : _vm._e(),
      _vm._v(" "),
      _vm.isImage
        ? _c("img", {
            staticStyle: { display: "none" },
            attrs: { src: _vm.src },
            on: { error: _vm.onImgError },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isImage,
              expression: "!isImage",
            },
          ],
        },
        [_vm._v(_vm._s(_vm.userInitial))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }