"use strict";

var _interopRequireDefault = require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/defineProperty.js"));
var _AxiosApi = _interopRequireDefault(require("./AxiosApi.js"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /*
 * @Author: wangtao
 * @Date: 2021-04-23 14:55:33
 * @LastEditors: 汪滔
 * @LastEditTime: 2021-06-25 13:59:49
 * @Description: file content
 */
var apiList = {
  page: {
    url: "/msg/page",
    method: 'POST'
  },
  mark: {
    url: "/msg/mark",
    method: 'GET'
  },
  save: {
    url: "/msg",
    method: 'POST'
  },
  delete: {
    url: "/msg",
    method: 'DELETE'
  },
  preview: {
    method: 'POST',
    url: "/msg/preview"
  },
  export: {
    method: 'POST',
    url: "/msg/export"
  },
  import: {
    method: 'POST',
    url: "/msg/import"
  }
};
var _default = exports.default = {
  page: function page(data) {
    var custom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _AxiosApi.default)(_objectSpread(_objectSpread({}, apiList.page), {}, {
      data: data,
      custom: custom
    }));
  },
  save: function save(data) {
    return (0, _AxiosApi.default)(_objectSpread(_objectSpread({}, apiList.save), {}, {
      data: data
    }));
  },
  delete: function _delete(data) {
    return (0, _AxiosApi.default)(_objectSpread(_objectSpread({}, apiList.delete), {}, {
      data: data
    }));
  },
  mark: function mark(data) {
    return (0, _AxiosApi.default)(_objectSpread(_objectSpread({}, apiList.mark), {}, {
      data: data
    }));
  },
  get: function get(id) {
    return (0, _AxiosApi.default)({
      url: "/msg/".concat(id),
      method: 'GET'
    });
  },
  preview: function preview(data) {
    return (0, _AxiosApi.default)(_objectSpread(_objectSpread({}, apiList.preview), {}, {
      data: data
    }));
  },
  export: function _export(data) {
    return (0, _AxiosApi.default)(_objectSpread(_objectSpread({}, apiList.export), {}, {
      responseType: "blob",
      data: data
    }));
  },
  import: function _import(data) {
    return (0, _AxiosApi.default)(_objectSpread(_objectSpread({}, apiList.import), {}, {
      data: data
    }));
  }
};