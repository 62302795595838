"use strict";

var _interopRequireDefault = require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExpireTime = getExpireTime;
exports.getRefreshToken = getRefreshToken;
exports.getToken = getToken;
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
function getToken() {
  return _localstorage.default.get('ACCESS_TOKEN', '');
}
function getRefreshToken() {
  return _localstorage.default.get('REFRESH_TOKEN', '');
}
function getExpireTime() {
  return _localstorage.default.get('EXPIRE_TIME', 0);
}