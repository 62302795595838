"use strict";

var _interopRequireDefault = require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Login = _interopRequireDefault(require("./api/Login"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'App',
  created: function created() {
    //TODO 每次刷新页面会加载这个方法？是否能在这里判断 必须要缓存的Token ，用户信息等
    if (this.$store.state.account.token) {
      this.getResource();
    }
  },
  methods: {
    getResource: function getResource() {
      var _this = this;
      _Login.default.getResource().then(function (response) {
        var res = response.data;
        if (res.isSuccess) {
          var authorityResource = res.data;
          var permissionsList = authorityResource.resourceList || [];
          _this.$store.commit("account/setPermissions", permissionsList);
          _this.$store.commit("account/setAuthorityResource", authorityResource);
          _this.changeToken();
          // let timer = setTimeout(()=>{
          //   let refresh = localStorage.getItem('dxmh-web-boot_REFRESH_TOKEN')
          // },7200000)
          // this.loginSuccess();
        } else {
          // this.getCodeImage();
        }
      });
    },
    //登录两小时后自动刷新token
    changeToken: function changeToken() {
      setInterval(function () {
        localStorage['dxmh-web-boot_TOKEN'] = localStorage.getItem('dxmh-web-boot_REFRESH_TOKEN');
      }, 7200000);
    }
  }
};