"use strict";

var _interopRequireDefault = require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _defineProperty2 = _interopRequireDefault(require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/defineProperty.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _index = _interopRequireDefault(require("@/store/index"));
var _Login = _interopRequireDefault(require("@/api/Login.js"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
_vue.default.use(_vueRouter.default);
var constRouter = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/redirect/index'], resolve);
    }
  }]
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/error-page/404'], resolve);
  },
  hidden: true //代表该条路由不在菜单展示，一般为登录页，404页等
}, {
  path: '/login',
  name: '登录页',
  component: function component(resolve) {
    return require(['@/views/login/index'], resolve);
  }
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/dashboard/index'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/profile',
  component: _layout.default,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/profile/index'], resolve);
    },
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}, {
  path: '/resources',
  component: _layout.default,
  redirect: '/resources/msg',
  hidden: true,
  alwaysShow: true,
  meta: {
    title: '资源中心',
    icon: '',
    noCache: true,
    breadcrumb: true
  },
  children: [{
    hidden: true,
    alwaysShow: false,
    path: '/resources/msg/edit',
    component: function component(resolve) {
      return require(['@/views/dxmh/resources/msg/edit'], resolve);
    },
    name: '发送消息',
    meta: {
      title: '发送消息',
      icon: '',
      noCache: true,
      breadcrumb: true
    }
  }, {
    hidden: true,
    path: '/resources/sms/edit',
    component: function component(resolve) {
      return require(['@/views/dxmh/resources/sms/edit'], resolve);
    },
    name: 'smsEdit',
    meta: {
      title: '发送短信',
      icon: '',
      noCache: true
    }
  }]
}, {
  path: '/error',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'ErrorPages',
  meta: {
    title: 'errorPages',
    icon: '404'
  },
  children: [{
    path: '404',
    component: function component(resolve) {
      return require(['@/views/error-page/404'], resolve);
    },
    name: 'Page404',
    meta: {
      title: 'page404',
      noCache: true
    }
  }]
},
// 消息中心
{
  path: '/resources',
  component: _layout.default,
  alwaysShow: true,
  //代表该路由信息要作为菜单目录显示，就是可以收缩
  hidden: false,
  redirect: '/resources/msg',
  children: [{
    path: '/resources/msg',
    component: function component(resolve) {
      return require(['@/views/dxmh/resources/msg/index'], resolve);
    },
    name: '我的消息',
    meta: {
      title: '我的消息',
      icon: '',
      breadcrumb: true
    }
  }]
}, {
  path: '/channel-management/view',
  name: 'channelManagementView',
  component: function component(resolve) {
    return require(['@/views/dxmh/market-management/channel-management/view.vue'], resolve);
  }
}, {
  path: '/business-management/view',
  name: 'businessManagementView',
  component: function component(resolve) {
    return require(['@/views/dxmh/market-management/business-management/view.vue'], resolve);
  }
}, {
  path: '/financial-management/print',
  name: 'financialManagementPrint',
  component: function component(resolve) {
    return require(['@/views/dxmh/financial-management/components/print.vue'], resolve);
  }
}
// {
//   path: '/channel-management',
//   component: Layout,
//   hidden: true,
//   meta: {
//     title: '渠道管理', icon: '', noCache: true, breadcrumb: true
//   },
//   children:[
//     {
//       path:'view',
//       component: resolve => require(['@/views/dxmh/market-management/channel-management/view.vue'], resolve),
//       name: 'ChannelManagementView',
//       meta: {
//         title: '查看', icon: '', noCache: true
//       }
//     }
//   ],

// }
];
var router = new _vueRouter.default({
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constRouter
});
var whiteList = ['/login'];

// 异步的路由信息， 同时，左侧菜单也会根据这个来显示
var asyncRouter;

// 导航守卫，渲染动态路由，跳转路由验证用户登录状态
router.beforeEach(function (to, from, next) {
  _nprogress.default.start();
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    var token = _localstorage.default.get('TOKEN', '');
    var user = _localstorage.default.get('USER');
    var userRouter = []; //本地缓存中的路由
    if (token && token.length && user) {
      if (!asyncRouter) {
        if (userRouter.length == 0) {
          _Login.default.getRouter({}).then(function (response) {
            var res = response.data;
            asyncRouter = res.data;
            if (!(asyncRouter && asyncRouter.length > 0)) {
              asyncRouter = [];
            }
            asyncRouter.push({
              alwaysShow: false,
              component: "error-page/404",
              hidden: false,
              name: "404",
              path: "*"
            });
            _index.default.commit('account/setRoutes', asyncRouter);
            go(to, next);
          });
        } else {
          asyncRouter = userRouter;
          go(to, next);
        }
      } else {
        next();
      }
    } else {
      if (to.path === '/login') {
        next();
      } else {
        _localstorage.default.clear();
        next('/login');
      }
    }
  }
});
router.afterEach(function () {
  _nprogress.default.done();
});
function go(to, next) {
  asyncRouter = filterAsyncRouter(asyncRouter);
  router.addRoutes(asyncRouter);
  next(_objectSpread(_objectSpread({}, to), {}, {
    replace: true
  }));
}
function filterAsyncRouter(routes, parent) {
  return routes.filter(function (route) {
    var component = route.component;
    if (component) {
      if (route.component === 'Layout') {
        if (route.children && route.children.length > 0 && parent) {
          route.component = function (resolve) {
            require(['@/components/RouterWrap/RouterWrap.vue'], resolve);
          };
        } else {
          route.component = _layout.default;
        }
      } else if (typeof component === 'string') {
        route.component = view(component);
      }
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, route);
      }
      return true;
    }
  });
}
function view(path) {
  return function (resolve) {
    require(["@/views/".concat(path, ".vue")], resolve);
  };
}

/* 路由异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面 */
router.onError(function (error) {
  _localstorage.default.clear();
  location.reload();
  location.replace('/');
});
var _default = exports.default = router;