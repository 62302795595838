"use strict";

var _interopRequireDefault = require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.object.entries");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es7.array.includes");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _jsBase = require("js-base64");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// 请求添加条件，如token
_axios.default.interceptors.request.use(function (config) {
  var isToken = config.headers["X-isToken"] === false ? config.headers["X-isToken"] : true;
  var token = _localstorage.default.get("TOKEN", "");
  if (token && isToken) {
    config.headers.token = "Bearer " + token;
  }
  var clientId = process.env.VUE_APP_CLIENT_ID;
  var clientSecret = process.env.VUE_APP_CLIENT_SECRET;
  config.headers["Authorization"] = "Basic ".concat(_jsBase.Base64.encode("".concat(clientId, ":").concat(clientSecret)));
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 接口返回处理
_axios.default.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});
function handleError(error, reject, opts) {
  // debugger
  var isAlert = opts.custom ? opts.custom["isAlert"] : true;
  isAlert = isAlert === undefined ? true : isAlert;
  if (isAlert) {
    if (error.code === "ECONNABORTED") {
      (0, _elementUi.Message)({
        message: "请求超时"
      });
    } else if (error.response && error.response.data) {
      if (error.response.status === 403 || error.response.status === 401) {
        var resData = error.response.data;
        if (resData.code === 401) {
          _elementUi.MessageBox.alert(resData.msg, {
            confirmButtonText: "确定",
            callback: function callback() {}
          });
          return;
        }
        _elementUi.MessageBox.alert(resData.msg || "登录已失效，请重新登录", "提醒", {
          confirmButtonText: "确定",
          callback: function callback() {
            _localstorage.default.clear();
            window.location.hash = "/login";
            window.location.reload();
          }
        });
      } else if (error.response.status === 500) {
        (0, _elementUi.Message)({
          message: error.response.data
        });
      } else {
        var _resData = error.response.data;
        if (_resData.code === 40000 || _resData.code === 40001 || _resData.code === 40002 || _resData.code === 40003 || _resData.code === 40005 || _resData.code === 40006 || _resData.code === 40008 || _resData.code === 40009) {
          _elementUi.MessageBox.alert(_resData.msg || _resData.message, "提醒", {
            confirmButtonText: "确定",
            callback: function callback() {
              _localstorage.default.clear();
              window.location.hash = "/login";
              window.location.reload();
            }
          });
        } else if (_resData.msg) {
          (0, _elementUi.Message)({
            message: _resData.msg
          });
        } else if (_resData.message) {
          (0, _elementUi.Message)({
            message: _resData.message
          });
        }
      }
    } else if (error.message) {
      (0, _elementUi.Message)({
        message: error.message
      });
    }
  }
  reject(error);
}
function handleSuccess(res, resolve, opts) {
  var isAlert = opts.custom ? opts.custom["isAlert"] : true;
  isAlert = isAlert === undefined ? true : isAlert;
  var resData = res.data;
  if (resData.isSuccess === false) {
    // 未登录
    if (resData.code === 40000 || resData.code === 40001 || resData.code === 40002 || resData.code === 40003 || resData.code === 40005 || resData.code === 40006 || resData.code === 40008) {
      // debugger
      _elementUi.MessageBox.alert(resData.msg, "提醒", {
        confirmButtonText: "确定",
        callback: function callback() {
          window.location.hash = "/login";
        }
      });
    } else {
      if (isAlert) {
        _elementUi.Message.error(resData.msg);
      }
    }
  }
  resolve(res);
}

// http请求
var httpServer = function httpServer(opts) {
  // 公共参数
  var publicParams = {
    ts: Date.now()
  };

  // http默认配置
  var method = opts.method.toUpperCase();
  // baseURL
  // 开发环境： /api                 // 开发环境在 vue.config.js 中有 devServer.proxy 代理
  // 生产环境： http://IP:PORT/api   // 生产环境中 代理失效， 故需要配置绝对路径
  var httpDefaultOpts = {
    method: method,
    baseURL: process.env.VUE_APP_REQUEST_DOMAIN_PREFIX + process.env.VUE_APP_BASE_API,
    url: opts.url,
    responseType: opts.responseType || "",
    timeout: opts.custom && opts.custom["timeout"] || 30000
  };
  if (opts["meta"]) {
    httpDefaultOpts.headers = opts["meta"];
  }
  var dataRequest = ["PUT", "POST", "PATCH"];
  if (dataRequest.includes(method)) {
    httpDefaultOpts.data = opts.data || {};
    httpDefaultOpts.params = publicParams;
  } else {
    httpDefaultOpts.params = _objectSpread(_objectSpread({}, publicParams), opts.data || {});
  }
  // formData转换
  if (opts.formData) {
    httpDefaultOpts.transformRequest = [function (data) {
      var formData = new FormData();
      if (data) {
        Object.entries(data).forEach(function (item) {
          formData.append(item[0], item[1]);
        });
      }
      return formData;
    }];
  }
  var promise = new Promise(function (resolve, reject) {
    (0, _axios.default)(httpDefaultOpts).then(function (response) {
      handleSuccess(response, resolve, opts);
    }).catch(function (error) {
      handleError(error, reject, opts);
    });
  });
  return promise;
};
var _default = exports.default = httpServer;