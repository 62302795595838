"use strict";

var _interopRequireDefault = require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AxiosApi = _interopRequireDefault(require("./AxiosApi.js"));
/*
 * @Author: wangtao
 * @Date: 2021-04-23 14:55:33
 * @LastEditors: 汪滔
 * @LastEditTime: 2021-04-26 17:24:36
 * @Description: file content
 */

var apiList = {
  captcha: "/anno/captcha",
  login: "/noToken/login",
  logout: "/noToken/logout",
  router: "/menu/router",
  resource: "/resource/visible"
};
var _default = exports.default = {
  getCaptcha: function getCaptcha(randomId) {
    return (0, _AxiosApi.default)({
      method: 'GET',
      url: apiList.captcha + "?key=".concat(randomId),
      responseType: 'arraybuffer',
      meta: {
        "X-isToken": false
      }
    });
  },
  login: function login(data, meta) {
    return (0, _AxiosApi.default)({
      method: 'POST',
      url: apiList.login,
      data: data,
      meta: meta
    });
  },
  logout: function logout(data, meta) {
    return (0, _AxiosApi.default)({
      method: 'POST',
      formData: true,
      url: apiList.logout,
      data: data,
      meta: meta
    });
  },
  getRouter: function getRouter(data) {
    return (0, _AxiosApi.default)({
      method: 'GET',
      url: apiList.router,
      data: data || {}
    });
  },
  getResource: function getResource(data) {
    return (0, _AxiosApi.default)({
      method: 'GET',
      url: apiList.resource,
      data: data || {}
    });
  }
};