"use strict";

var _interopRequireDefault = require("E:/oms/dxmh-web-boot/node_modules/_@babel_runtime@7.23.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
/*
 * @Author: 谭鑫
 * @Date: 2022-06-21 10:37:32
 * @LastEditors: 谭鑫
 * @LastEditTime: 2022-06-27 14:16:38
 * @Description: 
 */
var _default = exports.default = {
  namespaced: true,
  state: {
    refreshToken: _localstorage.default.get('REFRESH_TOKEN'),
    token: _localstorage.default.get('TOKEN', ''),
    tenant: _localstorage.default.get('TENANT', ''),
    expireTime: _localstorage.default.get('EXPIRE_TIME', 0),
    user: _localstorage.default.get('USER') || {},
    permissions: _localstorage.default.get('PERMISSIONS') || [],
    authorityResource: _localstorage.default.get('AUTHORITY_RESOURCE') || {
      "enabled": true,
      "caseSensitive": false,
      "resourceList": [],
      "roleList": []
    },
    routes: []
  },
  mutations: {
    setTenant: function setTenant(state, val) {
      _localstorage.default.save('TENANT', val);
      state.accessToken = val;
    },
    setRefreshToken: function setRefreshToken(state, val) {
      _localstorage.default.save('REFRESH_TOKEN', val);
      state.refreshToken = val;
    },
    setToken: function setToken(state, val) {
      _localstorage.default.save('TOKEN', val);
      state.token = val;
    },
    setExpireTime: function setExpireTime(state, val) {
      _localstorage.default.save('EXPIRE_TIME', val);
      state.expireTime = val;
    },
    setUser: function setUser(state, val) {
      _localstorage.default.save('USER', val);
      state.user = val;
    },
    setPermissions: function setPermissions(state, val) {
      _localstorage.default.save('PERMISSIONS', val);
      state.permissions = val;
    },
    setAuthorityResource: function setAuthorityResource(state, val) {
      _localstorage.default.save('AUTHORITY_RESOURCE', val);
      state.authorityResource = val;
    },
    setRoutes: function setRoutes(state, val) {
      // db.save('USER_ROUTER', val)
      state.routes = val;
    }
  }
};